@keyframes blink {
    0% {
      color: transparent;
    }
    50%{
    color: white;
    }
    100% { color:transparent}
  }

.blinkingCursor{
    animation-name: blink;
    animation-duration: 0.65s;
    animation-iteration-count: infinite;
}


  